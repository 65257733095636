.pitch-video-billboard {
  @include media-breakpoint-up(lg) {
    max-height: 50vh;
  }
}

.pitch-closed-label {
  background: #e3e4e6;
  border-radius: 10px;
  color: #9ea1a9;
  padding: 1rem;
}

.actions-bar {
  background: $white;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);
  padding: 1rem;
}

.btn-burger {
  background-color: pink;
}

// DONATION PILLS

.donate-pill {
  background: $pink;
  border: none;
  border-radius: 100px;
  padding: 0.35rem 0.8rem;
  margin: 0.5rem 0.3rem;
  font-weight: bold;
  font-size: 1rem;
  &:hover {
    background: lighten($pink, 10%);
  }
  @media only screen and (max-width: 23.125rem) {
    font-size: 0.8rem;
  }
}

.donate-custom {
  border: none;
  border-radius: 100px;
  background: $pink;
  padding: 0.35rem 0.8rem;
  font-weight: bold;
  text-align: center;
  margin: 0.5rem 0.3rem;
  width: 7ch;
  font-size: 1rem;
  height: 2.5rem;

  @media only screen and (max-width: 23.125rem) {
    font-size: 0.8rem;
    height: 2rem;
  }

  &:hover,
  &:focus {
    background: darken($pink, 10%);
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.donate-pill,
.donate-custom {
  &.selected {
    background: $primary;
    color: $white;
  }
}

// SPLASH BILLBOARD

// .splash-billboard {
//   height: calc(80vh - 5.125rem);
//   max-height: 42rem;
//   position: relative;
//   background: $pink url(./img/bgv-word-cloud-mob.png) no-repeat center center;
//   background-size: cover;
//   background-size: 100%;
//   margin-bottom: 2rem;

//   @include media-breakpoint-up(md) {
//     background: $pink url(./img/bgv-word-cloud-dsk.png) no-repeat center center;
//     background-size: cover;
//   }

//   &::after {
//     content: '';
//     width: 100%;
//     height: 5.5rem;
//     background: url(./img/ragged-edge.svg) repeat-x center top;
//     background-size: 100%;
//     position: absolute;
//     left: 0;
//     bottom: -5.5rem;
//     top: 99%;
//   }
// }

.secondary-billboard {
  display: flex;
  // height: 80vh;
  // max-height: 44rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .portrait {
    padding: 2rem;
    z-index: 2;
    width: auto;
    > img {
      height: 24rem;
      width: auto;
    }
  }

  .tagline {
    z-index: 3;
    margin-top: -7rem;
    position: relative;
  }

  .t1,
  .t2,
  .t3 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  //   span.t1 {
  //     background: url(./img/tear-1.png) no-repeat center center;
  //     background-size: 80%;
  //     min-height: 7rem;
  //     z-index: 4;
  //   }

  //   span.t2 {
  //     background: url(./img/tear-2.png) no-repeat center center;
  //     background-size: 60%;
  //     min-height: 5.625rem;
  //     margin-top: -3rem;
  //     z-index: 5;
  //     position: static;
  //   }

  //   span.t3 {
  //     background: url(./img/tear-3.png) no-repeat center center;
  //     background-size: 80%;
  //     min-height: 8rem;
  //     margin-top: -3.5rem;
  //     z-index: 3;
  //   }

  @include media-breakpoint-up(lg) {
    padding: 8rem 2rem 4rem 2rem;
    flex-direction: row;

    .tagline {
      margin-top: 0rem;
    }
  }
}

.mini-avatar {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 200px;
  background: $dark;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: 200px;
  }
}

.competition-avatar {
  height: 5rem;
  width: 5rem;
  object-fit: cover;
  border-radius: 50%;
  background: $dark;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: 200px;
  }
}

.name-comment {
  p {
    max-width: 50rem;
  }
}

.donation-card {
  box-shadow: 0 0.40228px 4.0228px $pink;
  text-decoration: none;
  color: $gray-800;
  transition: 0.3s;
  border-radius: $border-radius;
  margin-bottom: 2rem;

  .play-box {
    width: 100%;
    height: 10rem;
    position: relative;

    &:hover {
      cursor: pointer;

      i.icon-play {
        color: $gold;
      }

      img.yt-cover {
        opacity: 1;
      }
    }

    i.icon-play {
      color: $white;
      position: absolute;
      z-index: -1;
      width: 2rem;
      height: 2rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 2rem;
    }

    img.yt-cover {
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -3;
      position: relative;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      opacity: 0.9;
      transition: 0.3s;
    }

    .title-box {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: -2;
      height: 100%;
      width: 100%;
      background: $fade-grd;
      padding: 1rem 1.5rem;
      color: $white;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }

  .body {
    padding: 1rem;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

// PITCH CARDS

.pitch-card {
  transition: 0.3s ease;
  box-shadow: 0 0.40228px 4.0228px $pink;
  border-bottom-right-radius: $border-radius;
  border-bottom-left-radius: $border-radius;

  .cover {
    height: 10rem;
    width: 100%;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    position: relative;
    display: block;

    .title-box {
      position: absolute;
      z-index: 2;
      left: 0;
      bottom: 0;
      background: $fade-grd;
      color: $white;
      padding: 1rem 1.5rem;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      background-color: $primary;
    }

    > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }
  }

  .body {
    padding: 1.5rem 1.5rem 1rem 1.5rem;
  }
}

.social-links {
  font-size: 2.2rem;
}

// EVENT TILES

.event-card-tile {
  display: block;
  transition: 0.3s ease;
  border: 0px transparent;
  margin-bottom: 2rem;

  @include media-breakpoint-down(sm) {
    &:first-of-type {
      margin-left: 1rem;
    }
  }

  &:hover {
    text-decoration: none;
    .event-card {
      box-shadow: 0 0.90228px 9.0228px #f2aea1;
    }

    .card-img {
      opacity: 1;
    }
  }
}

.event-card {
  box-shadow: 0 0.40228px 4.0228px $pink;
  text-decoration: none;
  color: $dark-blue;
  transition: 0.3s;

  .event-card-title {
    position: relative;
    height: 19rem;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    background-color: $primary;
  }

  .title-box {
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    text-align: left;
    color: #fff;
    background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.7));
    width: 100%;
    padding: 1rem 2rem;
  }

  .card-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    opacity: 0.8;
    transition: 0.3s ease;
  }
}

.slanted-card {
  position: relative;
  clip-path: polygon(8% 0, 100% 0, 92% 100%, 0 100%);
  height: 22rem;
  background: black;
  transition: ease 0.3s;
  margin-bottom: 2rem;
  filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5));

  @include media-breakpoint-down(sm) {
    &:first-of-type {
      margin-left: 2rem;
    }
  }

  &.gold {
    background: $secondary;
  }

  &:hover {
    .cover-frame {
      mix-blend-mode: normal;
    }
  }

  .title-box {
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 2rem;
    background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.7));
    color: $white;
    clip-path: polygon(8% 0, 100% 0, 92% 100%, 0 100%);
  }

  .cover-frame {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    z-index: 1;
    clip-path: polygon(8% 0, 100% 0, 92% 100%, 0 100%);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    mix-blend-mode: luminosity;
  }

  img.cover {
    object-fit: fill;
    height: 100%;
    width: 100%;
    clip-path: polygon(8% 0, 100% 0, 92% 100%, 0 100%);
    mix-blend-mode: luminosity;
  }
}

// TYPE HELPERS

.font-display {
  font-family: $font-display;
}

.font-script {
  font-family: $font-script;
}

.font-primary {
  font-family: $font-primary;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  &.font-primary {
    font-family: $font-primary !important;
  }

  &.font-script {
    font-family: $font-script !important;
  }
}

.checkout-summary {
  padding-top: 1rem;
  border-top: 1px solid $pink;
}
.data-bubble {
  height: 3.2rem;
  width: 3.2rem;
  border-radius: 200px;
}

.container.auth-container {
  max-width: 30rem;
}

.container.ext-container {
  max-width: 45rem;
}

.container.ext-l-container {
  max-width: 105rem;
}

.divider {
  position: relative;
}
.black-line {
  width: 100%;
  border-top: 1px solid $black;
  margin-top: 12px;
}

.font-12 {
  font-size: 0.75rem;
}
.font-13 {
  font-size: 0.8rem !important;
}
.font-14 {
  font-size: 0.875rem;
}
.font-16 {
  font-size: 1rem;
}
.font-20 {
  font-size: 1.25rem;
}
.font-24 {
  font-size: 1.5rem;
}
.font-28 {
  font-size: 1.75rem;
}
.font-32 {
  font-size: 2rem;
}
.font-40 {
  font-size: 2.5rem;
}
.font-48 {
  font-size: 3rem;
}
.font-60 {
  font-size: 4rem;
}
.font-72 {
  font-size: 5rem;
}

@include media-breakpoint-down(sm) {
  .hz-scroller {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap !important;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.swipe-me {
  animation: animationFrames bezier 2.8s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
  -webkit-animation: animationFrames linear 2.8s;
  -webkit-animation-iteration-count: infinite;
  -webkit-transform-origin: 50% 50%;
  @include media-breakpoint-up(md) {
    display: none;
  }
}
@keyframes animationFrames {
  0% {
    transform: translate(0px, 0px);
    opacity: 0;
  }
  25% {
    transform: translate(-32px, 0);
    opacity: 1;
  }
  35% {
    transform: translate(-32px, 0);
    opacity: 0;
  }
  36% {
    transform: translate(0px, 0);
    opacity: 0;
  }
  60% {
    transform: translate(-32px, 0);
    opacity: 1;
  }
  70% {
    transform: translate(-32px, 0px);
    opacity: 0;
  }
  100% {
    transform: translate(-32px, 0px);
    opacity: 0;
  }
}

.first-time-visit-toast {
  background: white !important;
  color: black !important;
}

.first-time-visit-progress {
  background: $pink !important;
}

.podium-card {
  position: relative;
  min-height: 60vh;
  border-radius: $border-radius;
  transition: 0.3s ease;
  &.winner {
    min-height: 45vh;
    @include media-breakpoint-up(lg) {
      min-height: 60vh;
    }
  }

  &.runner-up {
    min-height: 12rem;
  }

  &:hover {
    box-shadow: 0 0.40228px 4.0228px rgba(50, 50, 0, 0.3);
    .cover img {
      opacity: 1;
    }
  }
  .cover {
    background: $secondary;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: $border-radius;
    img {
      transition: 0.3s ease;
      opacity: 0.9;
      object-fit: cover;
      width: 100%;
      height: 100%;
      display: block;
      border-radius: $border-radius;
    }
  }
  .ribbon {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    z-index: 3;
  }
  .podium-card-title {
    .title-box {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 2;
      height: 100%;
      width: 100%;
      background: $fade-grd;
      padding: 1rem 1.5rem;
      color: $white;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }
}
.runner-up-card {
  min-height: 18rem;
  position: relative;
  border-radius: $border-radius;
  // box-shadow: 0 0.40228px 4.0228px rgba(50, 50, 0, 0.3);
  transition: 0.3s ease;
  &:hover {
    box-shadow: 0 0.40228px 4.0228px rgba(50, 50, 0, 0.3);
  }
  &:hover {
    text-decoration: none;
    .overlay {
      opacity: 0;
    }
    .play-box img.yt-cover {
      opacity: 1;
    }
  }
  .overlay {
    // z-index: 4;
    position: absolute;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.15;
    border-radius: $border-radius;
    transition: 0.3s ease;
  }
  .play-box {
    width: 100%;
    height: 10rem;
    position: relative;
    background-color: $primary;
    border-top-right-radius: $border-radius;
    border-top-left-radius: $border-radius;
    i.icon-play {
      color: $white;
      position: absolute;
      z-index: 3;
      width: 2rem;
      height: 2rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 2rem;
    }
    img.yt-cover {
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
      position: relative;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      opacity: 0.8;
    }
    .title-box {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 2;
      height: 100%;
      width: 100%;
      background: $fade-grd;
      padding: 1rem 1.5rem;
      color: $white;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      // background-color: $primary;
    }
  }
  .body {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

// FONT FAMILIES

@font-face {
  font-family: "Hatton";
  src: url("./fonts/hatton-semibold-webfont.woff2") format("woff2"),
    url("./fonts/hatton-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Quentin";
  src: url("./fonts/quentin-webfont.woff2") format("woff2"),
    url("./fonts/quentin-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.Toastify__toast--success {
  background: #19a77e !important;
}

.col-auto.limiter-80 {
  max-width: 80%;
}

.btn.back-btn-chip {
  width: 2.2em;
  height: 2.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $dark-blue;
  background: $pink;
  border-radius: 200px;
  text-align: center;
  &:hover,
  &:focus {
    color: $secondary;
  }
}

nav.bgv > ol.breadcrumb {
  margin-top: 0;
  margin-bottom: 0;
}

.admin-toolbar {
  @include media-breakpoint-down(md) {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
  }
  ul {
    margin: 0;
    width: 100%;
    justify-content: flex-start;
    @include media-breakpoint-up(md) {
      justify-content: center;
    }
    span.link {
      display: inline-block;
      padding: 1rem;
      font-weight: 500;
      color: $gray;
      display: flex;
      align-items: center;
      &.active {
        background: $white;
        color: $gray;
        > i.link-icon {
          color: $gray;
        }
      }
      > span.link-label {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
      > i.link-icon {
        color: $gray;
      }
      &:hover {
        color: $dark-blue;
        text-decoration: none;
        > i.link-icon {
          color: $dark-blue;
        }
      }
    }
  }
}

.display-1.headline {
  font-size: 6rem !important;
}

.admin-home .icon-bgv-logo-pink-circle {
  padding-bottom: 0.6rem;
}

.accordion.faq > .card {
  border: none;
  > .card-header {
    margin-bottom: 0;
  }
}

.score-toggler.btn {
  i::before {
    transition: 0.3s ease;
    display: block;
  }

  &[aria-expanded="true"] {
    i::before {
      transform: rotateZ(180deg);
    }
  }
  &:focus {
    box-shadow: none;
  }
}

.rank-sublist li {
  margin-bottom: 1rem;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.rank-position {
  font-size: 1.5rem;
  @include media-breakpoint-up(md) {
    font-size: 2.5rem;
  }
}

.scoreboard-accordion {
  color: red;
}

section#scoredion {
  button.score-toggler {
    background: $ice-gray;
    border: transparent;
    &:hover {
      background: darken($ice-gray, 5%);
    }
  }
  .accordion > .card {
    border: transparent;
  }
  .card-body {
    padding: 0;
  }
  .card-header {
    margin-bottom: 0;
  }
}

.scoredion > .accordion > .card {
  border: transparent;
  background: white;
  .score-toggler {
    background: $ice-gray;
  }

  &:hover {
    background: white;
  }
  .accordion > .card {
    border: transparent;
  }
  .card-body {
    padding: 0;
  }
  .card-header {
    margin-bottom: 0;
  }
}

.remove-img-btn {
  font-size: x-small !important;
}

.hiw-img {
  height: 50%;
}
.btn.btn-delete {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.chart-container {
  background-color: #c3d4eb;
  min-height: 40vh;
}

.limit-container-size {
  max-width: 700px;
}

.force-container-size > .card {
  min-width: 700px;
}

.height-300 {
  min-height: 300px;
}

.line-chart {
  aspect-ratio: unset !important;
}

.full-height {
  height: 100%;
}

.loader-container {
  min-height: 40vh;
}
// Fastest Login
.google-icon {
  width: 1.7rem;
  height: 1.7rem;
}

//Share Modal
.container-shareprofile > .avatar-opener > .big-avatar {
  border: 6px solid $wine;
  height: 10rem;
  width: 10rem;
}

//Search Bar
.searchbar-height.form-control {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  &:focus {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  }
}
@keyframes search-slide-down {
  0% {
    transform: translateY(-34px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes search-slide-up {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-34px);
  }
}
.search-bar {
  z-index: 1;
  position: relative;
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  &.reveal {
    animation: search-slide-down 0.5s ease;
  }
  &.collapse {
    animation: search-slide-up 0.5s ease;
  }
}
.searchbar-iconfake {
  position: absolute;
  z-index: 15;
  top: 0.6rem;
  left: 0.5rem;
}

.btn.bgv-pill {
  background: $pale-pink;
  color: $dark;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover,
  &:focus {
    background: lighten($primary, 25%);
    color: $dark;
  }
  &.active {
    background: $primary;
    color: $white;
    &:hover {
      color: $white;
      background: lighten($primary, 7%);
    }
  }
}

.limit-size {
  height: 15vh;
}

.zindex-5 {
  z-index: 5 !important;
}

// Search Results
.btn.bgv-pill {
  background: $pale-pink;
  color: $dark;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover,
  &:focus {
    background: lighten($primary, 25%);
    color: $dark;
  }
  &.active {
    background: $primary;
    color: $white;
    &:hover {
      color: $white;
      background: lighten($primary, 7%);
    }
  }
}

.oflow {
  overflow: hidden;
}

.search-item {
  max-width: 540px;
  height: 10rem;
  @include media-breakpoint-up(md) {
    max-width: 1000px;
  }
}

.fit-image {
  width: 100%;
  height: auto;
}

.search-card-img {
  border-radius: 5px 0px 0px 5px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.searchr-noimg {
  width: 100%;
  height: auto;
  border: 5px 0px 0px 5px;
  background: $dark;
}

.search-results {
  a:hover {
    text-decoration: none;
  }
}

// FOUNDER CARDS

section.browse-founders {
  padding: 0 8px;
}

.card.card-founders {
  background-color: $white;
}

.card-founders {
  box-shadow: 0px 1.30435px 13.0435px rgba(202, 146, 135, 0.35);
  .card-body {
    text-align: center;
    padding: 1rem;
    h5 {
      font-size: 1.2em;
      overflow-wrap: normal;
    }
    p {
      font-size: 1em;
    }
    &:hover {
      text-decoration: none;
    }
    span {
      color: $red;
    }
  }
  .social-links {
    margin-top: 4%;
    z-index: 5;
    font-size: 1.7em;
  }

  a:hover {
    text-decoration: none;
  }
}

.img-rounded {
  overflow: hidden;
  border-radius: 150px;
  max-width: 116px;
  max-height: 116px;
  width: 6em;
  height: 6em;
  margin: 9% auto 4%;
  border: 4px solid $gold;
  background-color: $dark-blue;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.text-truncate-4-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.footer--subscribe {
  background: $pink;
  input {
    border-color: $dark-blue;
  }
  .btn {
    text-transform: uppercase;
    font-weight: 400;
  }

  .form-inline.justify-content-center {
    @include media-breakpoint-down(xs) {
      justify-content: left !important;
    }
  }
}

#social-media-a a {
  &:hover {
    text-decoration: none;
  }
}

.vl {
  border-left: 1px solid $pink;
  height: 35px;
}

.input-helper {
  font-size: smaller;
}

form.auth-form > .input-group > .form-control {
  &:focus {
    color: #000000;
    background-color: #fff;
    border-color: $pink;
    outline: 0;
    box-shadow: 0 0 0 0.1rem rgba(248, 220, 212, 15%);
  }
}

.upload-img-btn {
  height: 9.25rem;
  width: 9.25rem;
  border: 2px dashed;
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  border-radius: $border-radius;
  background: $white;
  i {
    transition: 0.3s ease;
  }
  &:hover {
    i {
      transform: translateY(-4px);
    }
  }
}

.upload-doc-btn {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  background: $white;
  outline: none;
  i {
    transition: 0.3s ease;
  }
  &:hover {
    i {
      transform: translateY(-4px);
    }
  }
  &:focus {
    outline: none;
  }
}

.upload-img-preview {
  height: 9.25rem;
  width: 9.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  border-radius: $border-radius;
  background: $black;
  position: relative;

  .remove {
    position: absolute;
    right: -1rem;
    top: -1rem;
    width: 2rem;
    height: 2rem;
    padding: 0;
    border-radius: 100px;
    opacity: 1;
    z-index: 0;
    border: 4px solid white;
    background: rgba($color: #000000, $alpha: 1);
    &:hover {
      opacity: 1;
      background: rgba($color: #000000, $alpha: 1);
    }
  }

  .replace {
    transition: 0.3s ease;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    border-radius: 100px;
    border: transparent;
    opacity: 1;
    z-index: 00;
    appearance: none;
    width: 3rem;
    height: 3rem;
    background: rgba($color: #000000, $alpha: 0.7);
    &:hover {
      opacity: 1;
      background: rgba($color: #000000, $alpha: 1);
      transform: translate(-50%, -58%);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    border-radius: $border-radius;
  }
}

.sdd-header {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  background: $black;
  width: 100%;
  justify-content: flex-start;
  position: relative;
  z-index: 2;
  @include media-breakpoint-up(lg) {
    justify-content: end;
  }
  .main-nav > ul {
    width: 100%;
    margin: 0;
    justify-content: flex-start;
    flex-direction: column;
    height: auto;
    transition: 0.3s ease;
    & > li {
      width: 100%;
    }
    @include media-breakpoint-up(lg) {
      justify-content: flex-end;
      flex-direction: row;
      & > li {
        width: auto;
      }
    }
    // Nav Styles Mobile
    @include media-breakpoint-down(lg) {
      .admin-link {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 1rem 1rem;
        color: $pink;
        font-weight: 500;
        white-space: nowrap;
        border-bottom: 1px solid transparentize($pink, 0.7);
        cursor: pointer;
        &:hover {
          color: $black;
          background: darken($pink, 10%);
        }
      }
    }
    // Nav Styles Desktop
    @include media-breakpoint-up(lg) {
      .admin-link {
        padding: 1rem 1rem;
        display: inline-block;
        border-bottom: 3px solid transparent;
        color: $pink;
        font-weight: 500;
        white-space: nowrap;
        cursor: pointer;
        padding: 2rem 1rem;
        &:hover {
          text-decoration: none;
          border-bottom: 3px solid $gold;
          background: transparent;
        }
        &.active {
          color: $black;
          background: darken($pink, 10%);
        }
      }
    }
  }
}

input[type="number"]::placeholder {
  text-align: left;
}

input[type="number"]:-ms-input-placeholder {
  text-align: left;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.ReactModal__Overlay {
  opacity: 0;
  transform: translateY(-100px);
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateY(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateY(-100px);
}

.max-h-75v {
  max-height: 75vh;
}

.pointer-cursor {
  cursor: pointer;
}

.anchor-button {
  background: transparent;
  border: none;
  color: #f2ad9f;
}

.anchor-button:focus {
  outline: none;
}

.ReactModal__Content {
  top: 30% !important;
  left: 50% !important;
}

.navbar-buttons {
  justify-content: space-between;
  width: 20rem;
}

.donation-card .icon-play {
  z-index: -1 !important;
}

.with-video {
  z-index: -2 !important;
}

.without-video {
  z-index: 0 !important;
}

.donation-card .yt-cover {
  z-index: -3 !important;
}

.font-48 {
  font-size: 3rem;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.selected-tab {
  font-weight: bold;
}

.white-text:not([disabled]) {
  color: white !important;
}

.pink-background {
  background-color: $secondary !important;
}

.form-control[readonly] {
  background-color: white !important;
}

.react-datepicker-popper {
  z-index: 3 !important;
}

.article-cover {
  width: 100%;
  height: 50vh;
  position: relative;
  .ribbon {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    z-index: 3;
  }
  @include media-breakpoint-up(md) {
    max-height: 25rem;
    img {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.clickable {
  cursor: pointer;
}

.upload-img-preview {
  height: 9.25rem;
  width: 9.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  border-radius: $border-radius;
  background: $black;
  position: relative;

  .remove {
    position: absolute;
    right: -1rem;
    top: -1rem;
    width: 2rem;
    height: 2rem;
    padding: 0;
    border-radius: 100px;
    opacity: 1;
    z-index: 0;
    border: 4px solid white;
    background: rgba($color: #000000, $alpha: 1);
    &:hover {
      opacity: 1;
      background: rgba($color: #000000, $alpha: 1);
    }
  }

  .replace {
    transition: 0.3s ease;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    border-radius: 100px;
    border: transparent;
    opacity: 1;
    z-index: 00;
    appearance: none;
    width: 3rem;
    height: 3rem;
    background: rgba($color: #000000, $alpha: 0.7);
    &:hover {
      opacity: 1;
      background: rgba($color: #000000, $alpha: 1);
      transform: translate(-50%, -58%);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    border-radius: $border-radius;
  }
}

h5.checkout {
  display: flex;
  justify-content: space-between;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.page-link {
  font-weight: bold;
}

.pitch-winner {
  p {
    text-align: center;
  }
}
input.checkout-donation {
  margin-top: 0px;
}

button.dropdown-toggle::after {
  display: none !important;
}

.react-datepicker__input-container:after {
  z-index: 0 !important;
}

.breadcrumb {
  background-color: white !important;
}

.crumb-link {
  cursor: pointer;
  color: $dark-blue;
  font-weight: 500;
  &:hover {
    color: $secondary;
  }
}

.crumb {
  color: $gray;
  cursor: default;
  font-weight: 500;
}

.input-group {
  > input.form-control.alt-outline-focus {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    padding-right: 2rem;
    &:focus {
      box-shadow: none;
      border: 3px solid lighten($secondary, 40%);
    }
  }
  button#search-trigger {
    z-index: 0;
    &:focus {
      box-shadow: none;
      border: 1px solid $primary;
      outline: auto;
      outline-color: darken($primary, 10%);
    }
  }
}

.lazy-load-image-background.blur {
  height: 100%;
  width: 100%;
}

.card-img.card-img-top {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

main {
  min-height: calc(90vh - 10rem);
}

.page-item.active {
  z-index: -10;
}

.pagination {
  z-index: 0;
  @include media-breakpoint-down(sm) {
    padding-left: 2em !important;
  }
}

.break-word {
  word-break: break-word;
}

.row-no-margin {
  display: flex;
  flex-wrap: wrap;
}

// Landing Page

.landing .container {
  max-width: 1400px;
}

.section.benefits {
  padding-right: 15px;
  padding-left: 15px;
}

.quote-mark {
  font-size: 5rem;
}

.testimonial-img {
  max-width: 460px;
  width: 90%;
  min-height: auto;
  margin: 20px auto;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.partner-icon {
  font-size: 10rem;
}

.big-icon {
  font-size: 15rem;
}

.square {
  background-color: $pink;
  height: 200px;
  width: 200px;
  margin: auto;
  border: black solid 2px;
  position: relative;
}

.top::after {
  content: "";
  height: 20px;
  width: 20px;
  position: absolute;
  background-color: $pale-pink;
  top: -12px;
  left: 45%;
  border-top: black solid 2px;
  border-left: black solid 2px;
  transform: rotate(45deg);
}

.with-line {
  position: relative;
}
.with-line:after {
  content: "";
  height: 1px;
  width: 90px;
  background: black;
  display: block;
  position: absolute;
  top: -10px;
}

.section-partners .with-line:after {
  background: #eacbbb;
}

.img-resized {
  width: 80%;
  margin: 0 auto;
}

.carousel .control-dots .dot {
  border: 1px solid black;
  background: transparent;
  box-shadow: none;
  width: 12px;
  height: 12px;
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  background: black;
}

.section-cta .bg-pinky:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #fff;
  top: 0;
  left: 45%;
}

.caps-text {
  text-transform: uppercase;
  color: black;
}

@include media-breakpoint-up(md) {
  #burger-toggle {
    display: none;
  }
  .responsive-nav-items {
    display: flex;
  }
}
@include media-breakpoint-down(md) {
  #burger-toggle {
    display: block;
  }
  .responsive-nav-items {
    list-style-type: none;
    display: none !important;
  }
  #nav-ul {
    flex-direction: column;
  }
}

.link:hover {
  text-decoration: underline;
}

::-ms-reveal {
  display: none !important;
}

.border-black {
  border-color: #000000;
}

.form-control:focus {
  border-color: #000000 !important;
  box-shadow: 0 0 0 0.1rem #f8dcd4 !important;
}

.btn-pill {
  border: 2px;
  border-color: black !important;
  border-radius: 20px !important;
}

.selected-pill {
  background-color: #000000 !important;
  border-radius: 20px !important;
}

.clickable {
  cursor: pointer;
}

//Stepper

.roundstep {
  border-radius: 50%;
  background-color: #000000;
  color: $pink;
  height: 45px;
  width: 45px;
}
.roundstep-pink {
  border-radius: 50%;
  background-color: $pink;
  color: $pink;
  height: 45px;
  width: 45px;
}
.filled-roundstep {
  border-radius: 50%;
  border: solid 1px black;
  background-color: transparent;
  color: $pink;
  height: 45px;
  width: 45px;
}

.stepper-black-line {
  width: 100%;
  border-top: 1px solid $black;
  margin-top: 12px;
}

.black-line-guided-search {
  width: 100%;
  border-top: 1px solid $black;
}

.section-description {
  background-color: #f5f5f5;
}

.no-border {
  border: none !important;
}

.full-width {
  width: 100%;
}

div#state-row select.custom-select {
  color: black;
}

div#state-row select.custom-select option:first-child {
  color: grey;
}

div#city-row select.custom-select:disabled {
  color: black;
  background-color: transparent;
}

// div#industry-row div.col-auto .btn-black:hover {
//   background-color: transparent;
//   color: black !important;
// }

//Checkbox switch

.just-a-container
  .custom-control-input:checked
  ~ .custom-control-label::before {
  color: pink;
  border-color: pink;
  background-color: black;
}

.pink-text-hover:hover {
  color: pink !important;
}

.btn-outline-black:hover {
  color: #f8dcd4 !important;
}

.btn-white.btn-outline-black:not(:disabled):not(.disabled):active,
.btn-white.btn-outline-black:not(:disabled):not(.disabled):hover,
.btn-white.btn-outline-black:not(:disabled):not(.disabled):focus {
  background-color: white;
  color: black !important;
}

//View Supplier profile

.big-avatar {
  height: 10rem;
  width: 10rem;
}

.supplier-big-avatar {
  height: 8.5rem;
  width: 8.5rem;
  border-radius: 300px;
  background: $pink;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 300px;
  }
}

.medium-avatar {
  height: 6rem;
  width: 6rem;
  border-radius: 300px;
  background: $pink;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 300px;
  }
}

.profile-media-button {
  height: 4rem;
  width: 4rem;
  border-radius: 5px;
  background: $pink;
  display: flex;
  justify-content: center;
  align-items: center;
}
.company-product-information > table {
  thead {
    background-color: $gray-100;
  }

  tr {
    border-top: 1px solid $gray-200 !important;
  }
}

.customer-service-product > table {
  thead {
    background-color: $gray-100;
  }

  tr {
    border-top: 1px solid $gray-200 !important;
  }
}

.meet-team-row {
  width: 75%;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.spinner-container {
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-45%, -50%);
}

.text-wine {
  color: #763949;
}

.team-member-btn {
  height: 9.25rem;
  width: 9.25rem;
}

.remove-teammate {
  text-align: center;
  justify-content: center;
}

input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 12px;
  width: 12px;
  background-color: white;
  border-radius: 1px !important;
  outline: 2px solid black;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

input[type="checkbox"]::after {
  font-family: "bgv-icons" !important;
  content: "\e90a";
  font-size: 13px;
  font-weight: 600;
  color: black;
  display: none;
}

input[type="checkbox"]:checked::after {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-content: center;
  .spinner-container {
    position: absolute;
    top: 50%;
    left: 45%;
    transform: translate(-45%, -50%);
  }
}

.loader-text {
  padding-top: 5rem;
}
.input-group.addon button.input-operator.double-push-r {
  right: 3rem;
}

.input-group.addon button.input-operator {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #adb5bd;
  background: #fff;
  right: 0.5rem;
}

.input-group.addon button.input-operator:hover {
  background: #e9ecef;
  color: #3a6bad;
}

.table-color-pinky {
  background-color: #f8dcd4;
  border-right: 2px solid #fff;
}

nav.users-tabs > a.nav-link.active {
  color: #000;
  background-color: #f8dcd4;
  border-color: transparent;
  font-weight: 700;
}

nav.users-tabs > a.nav-link {
  background-color: #f5f5f5;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.manage-users-actions .btn {
  border-radius: 0;
}

.table-height-hack {
  min-height: 20rem;
}

.admin-container .btn-link {
  text-decoration: none !important;
}

.next-button {
  border-radius: 50%;
}

// Manage Connections

.connection-list-item {
  width: 600px !important;
  border-radius: 5px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.40228px 4.0228px grey;
  @include media-breakpoint-down(md) {
    width: 100% !important;
  }
}
.connection-grid-item {
  width: 200px;
  align-items: center;
  margin: 5px;
  border-radius: 5px !important;
  box-shadow: 0 0.40228px 4.0228px grey;

  @include media-breakpoint-down(md) {
    width: 162px !important;
  }
}

.connection > .medium-avatar {
  height: 4.45rem;
  width: 5rem;
  @include media-breakpoint-down(md) {
    height: 2.75rem;
    width: 2.75rem;
  }
}
.connection > .medium-avatar.grid-item {
  height: 5rem;
  width: 5rem;
  @include media-breakpoint-down(md) {
    height: 7rem;
    width: 7rem;
  }
}

.connection > .result-avatar.grid-item {
  height: 6rem;
  width: 6rem;
}

.w-48 {
  width: 48% !important;
}

.connection-width-hack {
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.modal-cancel-btn:focus {
  box-shadow: 0 0 0 0.2rem $gray-200 !important;
}

.gray-btn-pill {
  color: white;
  background-color: #adb5bd;
  border-radius: 20px !important;
  width: 10rem;
  height: 1.75rem;

  &:focus {
    background-color: #5d5f61;
    outline: transparent;
  }

  @include media-breakpoint-down(md) {
    width: auto;
    min-width: 100%;
  }
}

.active-connection-filter {
  background-color: #5d5f61;
  border: transparent;
}

.accept-deny-connection {
  margin: auto;
  width: 10rem;
  @include media-breakpoint-down(md) {
    width: 90%;
  }
}

.tab-btn-width {
  width: 15.5rem;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.mobile-w-100 {
  @include media-breakpoint-down(md) {
    width: 100% !important;
  }
}

.filter-width-hack {
  width: auto !important;
  @include media-breakpoint-down(md) {
    width: 35% !important;
  }
}

.all.filter-width-hack {
  @include media-breakpoint-down(md) {
    width: 20% !important;
  }
}

.table-pagination {
  .active {
    .page-link {
      z-index: 3 !important;
      color: #fff !important;
      background-color: black !important;
      border-color: black !important;
    }
  }

  .page-link:hover {
    color: black;
    background-color: $pinky;
    border-color: $pinky;
  }
}

.grid-wrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  @media only screen and (max-width: 1366px) {
    grid-template-columns: repeat(3, 1fr);
  }
}
.connections-grid {
  @include media-breakpoint-down(md) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.search-grid {
  @include media-breakpoint-down(md) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.btn-section {
  max-width: 15rem;
}

.text-kinda-muted {
  color: #636363;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
}

.circle-icon-background {
  background-color: $black;
  border-radius: 50%;
  height: 28px;
  width: 28px;
}

// Onboarding

.container.onboarding {
  max-width: 50rem;
}

.foo-name {
  font-size: 0.875rem;
  @include media-breakpoint-down(md) {
    font-size: 0.85rem;
  }
}

.onboarding.section-description {
  @include media-breakpoint-down(md) {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
}

.pink-circle {
  background-color: $pinky;
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  @include media-breakpoint-down(md) {
    height: 6.5rem;
    width: 6.5rem;
  }

  i {
    font-size: 6rem;
    @include media-breakpoint-down(md) {
      font-size: 4rem;
    }
  }
}

main.how-it-works {
  .pink-circle {
    background-color: $pinky;
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    @include media-breakpoint-down(md) {
      height: 3rem;
      width: 3rem;
    }

    i {
      font-size: 3rem;
      @include media-breakpoint-down(md) {
        font-size: 2rem;
      }
    }
  }
}

.result-card {
  border-width: 2px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  @include media-breakpoint-down(md) {
    min-height: 230 !important;
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }
}

.area-label {
  border-style: solid;
  border-width: 1px;
  border-color: black;
  border-radius: 100px;
  display: inline-block;
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 10px;
  padding: 0px 15px;
}

.search-list-view {
  min-width: 45rem;

  @include media-breakpoint-down(md) {
    min-width: auto;
  }
}

// Supplier Onboarding revamp

.supplier-onboarding-container {
  width: 65% !important;
  @include media-breakpoint-down(md) {
    width: 100% !important;
  }
}

label.ps-responsive-font {
  font-size: 1.25rem;
  @include media-breakpoint-down(md) {
    font-size: 1.1rem !important;
  }
}

span.col-auto.ps-padding {
  padding-right: 15px;
  padding-left: 15px;
  @include media-breakpoint-down(md) {
    padding-left: 0px !important;
  }
}

.company-stage-card {
  width: 100% !important;
  border-radius: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.40228px 4.0228px grey;
  @include media-breakpoint-down(md) {
    width: 100% !important;
  }
}

.stage-circle-icon-background {
  background-color: $black;
  border-radius: 50%;
  height: 16px;
  width: 16px;
}

/*desktop view*/
.stage-modal {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.75rem;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

// Google SSO

.manage-connections-avatar {
  height: 6rem;
  width: 6rem;
  border-radius: 5px;
  background: $pink;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
}

.google-signin-wrapper {
  background-color: #fff;
  font-size: 16px;
  border-radius: 2px;
  height: 2.5rem;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}

.google-sso-text {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.inactive-tab {
  background-color: $gray-200 !important;
  color: $gray-600 !important;
}

.search-settings {
  background-color: $pinky;
}

.edit-search {
  border: solid;
  border-color: black;
  padding: 2px 4px;
  border-width: 2px;
  border-radius: 5px;
  cursor: pointer;
}

.edit-search span {
  font-size: 13px;
  margin: 4px 8px;
}

.edit-search i {
  margin: 4px 0px;
}

.fit-content {
  height: fit-content;
}

.btn:focus,
.btn.focus {
  &.settings-accordion {
    box-shadow: none !important;
  }
}

.border-bottom-black {
  border: solid;
  border-color: black;
  border-top: none;
  border-left: none;
  border-right: none;
  border-width: thin thin thin;
}

.text-align-left {
  text-align: left;
}

.opp-icon {
  font-size: 1.45rem;
  margin-bottom: 0.5rem;
}

button.settings-modal-btn {
  font-size: 1.1rem;
  padding: 8px 25px;
}

.table-hover tbody tr:hover {
  color: black !important;
  background-color: rgba(0, 0, 0, 0.025);
}

//Customer profile view

i.customer-opportunity-font {
  font-size: 4rem;

  @include media-breakpoint-down(md) {
    font-size: 2.5rem;
  }
}

p.customer-opportunity-font {
  font-size: 1.25rem;

  @include media-breakpoint-down(md) {
    font-size: 1rem;
  }
}

p.customer-opportunity-font.description {
  font-size: 1rem;

  @include media-breakpoint-down(md) {
    font-size: 0.6rem;
  }
}

.insight-icon {
  font-size: 15rem;
  @include media-breakpoint-down(md) {
    font-size: 8rem;
  }
}

.pt-1-5 {
  padding-top: 0.375rem;
}

.go-back-btn {
  background-color: black;
  border: none;
  padding: 6px;
  text-align: center;
  text-decoration: none;
  font-size: 22px;
  margin: 4px 2px;
  border-radius: 50%;
}

.navbar-logo {
  font-style: normal !important;
}

.navbar-logo::after {
  @include media-breakpoint-up(md) {
    content: url("./img/bsn-pink-logo.svg");
  }
}

.is-sticky {
  position: sticky;
  top: 0px;
  z-index: 3;
}

.btn-icon {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

button.btn-icon:focus {
  outline: none;
}

button.edit-search-btn {
  padding: 2px 6px;
}

.company-stage-i {
  border: solid #000 1px;
  border-radius: 100%;
  height: 1rem;
  width: 1rem;
}

.opportunity-checkbox-modal {
  margin-bottom: 0.25rem;
}

.result-banner {
  background-color: $pinky;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.opportunity-card {
  word-wrap: unset !important;
}

.opportunity-card > .card-body {
  padding-left: 1rem;
  padding-right: 1rem;
}

.contactus-atag {
  text-decoration: underline;
}

.user-cardlabel {
  text-transform: uppercase;
  font-weight: bold;
}

.user-connect-search {
  border-bottom: 1px solid black;
}

input.user-connect-search {
  border: none !important;
  -webkit-appearance: none;
}

input.user-connect-search:focus-visible {
  border: none !important;
  outline: none;
}

div.connect-result-container {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

span.result-top-border {
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-top: 1px solid rgb(192, 191, 191);
}

input:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

.text-dark-gray {
  color: #595e64 !important;
}

.text-light-gray {
  color: $gray-600 !important;
}

.notification > .medium-avatar {
  height: 5rem;
  width: 5rem;
  @include media-breakpoint-down(md) {
    height: 4rem;
    width: 4rem;
  }
}

.profile-reset-password-href {
  text-decoration: underline;
  color: #1827c1;
}

.main-banner {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  // background-attachment: fixed;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(./img/mainbanner.png);
  max-width: 100%;
  padding: 15rem 10rem 6rem;
  margin-top: -100px;
  display: flex;
  justify-items: center;
  flex-direction: column;
  align-items: center;

  @include media-breakpoint-down(sm) {
    padding: 7rem 4rem 3rem;
  }
}

.carrousel-image {
  max-height: 500px;
  width: 100%;
  object-fit: cover;
}

img.fix-size-40 {
  max-width: 40%;
  @include media-breakpoint-down(sm) {
    max-width: 100%;
  }
}

.role-selection-icon > .pink-circle {
  height: 5.25rem;
  width: 5.25rem;
  @include media-breakpoint-down(md) {
    height: 5.25rem;
    width: 5.25rem;
  }

  i {
    font-size: 4rem;
    @include media-breakpoint-down(md) {
      font-size: 4rem;
    }
  }
}

.role-selection-description {
  width: 50%;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}
.role-selection-action {
  width: 70%;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.btn-black:disabled {
  color: #fff !important;
  background-color: #000000;
  border-color: #000000;
}

.request-card {
  border-width: 2px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  min-height: 315px;
  @include media-breakpoint-down(md) {
    min-height: 325px !important;
  }
  @include media-breakpoint-down(sm) {
    min-height: 280px !important;
  }
}

.user-request.medium-avatar {
  @include media-breakpoint-down(md) {
    height: 4rem;
    width: 4rem;
  }
}

.user-management {
  width: 100%;
  background-color: #f1b8a89f;
  color: black;

  .col-auto {
    height: 100%;
  }

  .active {
    background-color: #fcebeb;
  }

  .col-auto:hover {
    background-color: #fcebeb;
  }
}

.industry-pill {
  border: solid 1px black;
  color: black;
  padding: 2px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  border-radius: 16px;
}
