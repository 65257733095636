@import './styles/variables';
@import './styles/bgv-globals';
@import './styles/bgv-icons';

@import '../node_modules/bootstrap/scss/bootstrap.scss';

@include media-breakpoint-up(md) {
  #burger-toggle {
    display: none;
  }
  .responsive-nav-items {
    display: flex;
  }
}
@include media-breakpoint-down(md) {
  #burger-toggle {
    display: block;
  }
  .responsive-nav-items {
    list-style-type: none;
    display: none !important;
  }
  #nav-ul {
    flex-direction: column;
  }
}
