@font-face {
  font-family: 'bgv-icons';
  src: url('./fonts/bgv-icons.eot?nl5th2');
  src: url('./fonts/bgv-icons.eot?nl5th2#iefix') format('embedded-opentype'),
    url('./fonts/bgv-icons.ttf?nl5th2') format('truetype'),
    url('./fonts/bgv-icons.woff?nl5th2') format('woff'),
    url('./fonts/bgv-icons.svg?nl5th2#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'bgv-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-activestategridview:before {
  content: '\e900';
}
.icon-add-user:before {
  content: '\e901';
}
.icon-bell:before {
  content: '\e902';
}
.icon-bgv-logo:before {
  content: '\e903';
}
.icon-bgv-logo-circle:before {
  content: '\e904';
}
.icon-bsn-diamond:before {
  content: '\e905';
}
.icon-bsn-dsk-logo:before {
  content: '\e906';
}
.icon-bsn-logo:before {
  content: '\e907';
}
.icon-calendar:before {
  content: '\e908';
}
.icon-champions:before {
  content: '\e909';
}
.icon-check:before {
  content: '\e90a';
}
.icon-chevron-down:before {
  content: '\e90b';
}
.icon-chevron-left:before {
  content: '\e90c';
}
.icon-chevron-right:before {
  content: '\e90d';
}
.icon-chevron-up:before {
  content: '\e90e';
}
.icon-close-x:before {
  content: '\e90f';
}
.icon-corporations:before {
  content: '\e910';
}
.icon-credit-card:before {
  content: '\e911';
}
.icon-email:before {
  content: '\e912';
}
.icon-export:before {
  content: '\e913';
}
.icon-eye:before {
  content: '\e914';
}
.icon-facebook:before {
  content: '\e915';
}
.icon-faq:before {
  content: '\e916';
}
.icon-filter:before {
  content: '\e917';
}
.icon-fire:before {
  content: '\e918';
}
.icon-fund:before {
  content: '\e919';
}
.icon-globe:before {
  content: '\e91a';
}
.icon-google:before {
  content: '\e91b';
}
.icon-gridview:before {
  content: '\e91c';
}
.icon-growth:before {
  content: '\e91d';
}
.icon-hiring:before {
  content: '\e91e';
}
.icon-home:before {
  content: '\e91f';
}
.icon-instagram:before {
  content: '\e920';
}
.icon-investment:before {
  content: '\e921';
}
.icon-kauffmanfoundation:before {
  content: '\e922';
}
.icon-knightfoundation:before {
  content: '\e923';
}
.icon-kroger:before {
  content: '\e924';
}
.icon-link:before {
  content: '\e925';
}
.icon-linkedin:before {
  content: '\e926';
}
.icon-linkedin-1:before {
  content: '\e927';
}
.icon-listview:before {
  content: '\e928';
}
.icon-listview-empty:before {
  content: '\e929';
}
.icon-location:before {
  content: '\e92a';
}
.icon-locationprofile:before {
  content: '\e92b';
}
.icon-lock:before {
  content: '\e92c';
}
.icon-markets:before {
  content: '\e92d';
}
.icon-mentor:before {
  content: '\e92e';
}
.icon-menu:before {
  content: '\e92f';
}
.icon-menu-1:before {
  content: '\e930';
}
.icon-minoq:before {
  content: '\e931';
}
.icon-nike:before {
  content: '\e932';
}
.icon-omaze:before {
  content: '\e933';
}
.icon-partnership:before {
  content: '\e934';
}
.icon-pdf:before {
  content: '\e935';
}
.icon-pitch:before {
  content: '\e936';
}
.icon-plant:before {
  content: '\e937';
}
.icon-play:before {
  content: '\e938';
}
.icon-plus:before {
  content: '\e939';
}
.icon-procurement:before {
  content: '\e93a';
}
.icon-product:before {
  content: '\e93b';
}
.icon-productservice:before {
  content: '\e93c';
}
.icon-productupdate:before {
  content: '\e93d';
}
.icon-question:before {
  content: '\e93e';
}
.icon-quote:before {
  content: '\e93f';
}
.icon-quoteup:before {
  content: '\e940';
}
.icon-refresh:before {
  content: '\e941';
}
.icon-search:before {
  content: '\e942';
}
.icon-service:before {
  content: '\e943';
}
.icon-service-1:before {
  content: '\e944';
}
.icon-settings:before {
  content: '\e945';
}
.icon-sprout:before {
  content: '\e946';
}
.icon-stripe:before {
  content: '\e947';
}
.icon-swipe:before {
  content: '\e948';
}
.icon-thunderbolt:before {
  content: '\e949';
}
.icon-tiles:before {
  content: '\e94a';
}
.icon-twitter:before {
  content: '\e94b';
}
.icon-upload-cloud:before {
  content: '\e94c';
}
.icon-user:before {
  content: '\e94d';
}
.icon-vertical-dots:before {
  content: '\e94e';
}
.icon-visa:before {
  content: '\e94f';
}
.icon-vote:before {
  content: '\e950';
}
.icon-wrench:before {
  content: '\e951';
}
.icon-youtube:before {
  content: '\e952';
}
